import "date-fns";
import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import axios from "axios";
import "./second.css";
import { Link, useHistory } from "react-router-dom";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Modal, Button } from "react-bootstrap";
function StepSecond(props) {
  const [errorMessage, seterrorMessage] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [buttonValue, setButtonValue] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState();
  const [appTime, setappTime] = useState();
  const history = useHistory();
  const [doctorname, setDoctorname] = useState("");
  const getdoctorID = props.match.params.doctorid;
  const uri = `${process.env.REACT_APP_URL}`
  useEffect(() => {
    axios
      .get(
        `${uri}/Doctor/GetDoctorProfile?DoctorId=${getdoctorID}&hospitalid=1`
      )
      .then(({ data }) => {
        const temp = JSON.parse(data.Data);
        // sessionStorage.clear();
        sessionStorage.setItem("docinfo", JSON.stringify(temp));
        setDoctorname(temp);
      })
      .catch((err) => {
        console.warn(err);
      });
  }, [getdoctorID, uri]);

  const handleDateChange = (date) => {
    let [datef, month, year] = [
      date.getDate(),
      date.getMonth() + 1,
      date.getFullYear(),
    ];
    datef = datef < 10 ? "0" + datef : datef;
    month = month < 10 ? "0" + month : month;
    let dateStr = year + "-" + month + "-" + datef;
    setAppointmentDate(datef + "-" + month + "-" + year);
    axios
      .get(
        `${uri}/Doctor/GetDoctorAppointments?hospitalid=1&Doc_Id=${getdoctorID}&date=${dateStr}`
      )
      .then(({ data }) => {
        if (data.SlotInfo) {
          let temp = JSON.parse(data.SlotInfo);
          setButtonValue(temp);
        } else {
          setButtonValue([]);
          seterrorMessage(`No time slot for this Date : ${dateStr}`);
          openModal();
        }
      })
      .catch((err) => {
        console.error(err);
      });
    setSelectedDate(date);
  };

  const callMePLZ = (e) => {
    buttonValue.map((value, index) => {
      document.getElementById(`${index}`).classList.remove("active");
      setappTime(e.target.value);
      return e.target.classList.add("active");
    });
  };

  const handleSubmit = (e) => {
    let [date, month, year] = [
      selectedDate.getDate(),
      selectedDate.getMonth() + 1,
      selectedDate.getFullYear(),
    ];
    date = date < 10 ? "0" + date : date;
    month = month < 10 ? "0" + month : month;
    let dateStr = date + "/" + month + "/" + year;

    sessionStorage.setItem("AppointmentDate", dateStr);
    if (appTime === undefined) {
      seterrorMessage(
        `You are booikng appointment at ${dateStr} and time is not selected.`
      );
      openModal();
      e.preventDefault();
    } else {
      seterrorMessage(
        `You are booikng appointment at ${dateStr} and time is ${appTime}.`
      );
      openModal();
      history.push(`/step-four/doctorid/${getdoctorID}`);
      sessionStorage.setItem("AppointmentTime", appTime);
    }
  };

  const visitLink = `/step-third/doctorid/${getdoctorID}`;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const openModal = () => {
    handleShow(true);
  };
  return (
    <>
      <div id="main-div-fluid">
        <div className="container-fluid text-white bgme pt-2">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-sm-9">
                <div className="row">
                  <div className="mx-2 my-1  step-third-icon">
                    <AddCircleIcon id="step-second-icon" />
                  </div>
                  <div className="text-white" id="smallText">
                    <h4>{doctorname && doctorname[0].Name}</h4>
                    <p>{doctorname && `${doctorname[0].Specialisation}`}&nbsp;&<br />
                      {doctorname && `${doctorname[0].Designation}`}</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="row align-items-center">
                  <div className="button mx-auto">
                    <Link to={visitLink} className="text-white">
                      View Profile
                    </Link>
                    &nbsp; &nbsp;<i className="fas fa-user"></i>
                  </div>
                  <div className="button ml-auto" id="back-button">
                    <button
                      className="btn btn-light rounded-pill px-3 px-md-4 float-md-right my-1"
                      onClick={history.goBack}
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container p-sm-2 mt-sm-5 ">
          <form onSubmit={handleSubmit} >
            <div className="row justify-content-around">
              <div
                className="col-md-5 text-center card p-sm-4 mt-sm-0 mt-2"
                id="choose-time-box"
              >
                <h3 className=" pt-sm-0 pt-3">Choose date</h3>
                <hr />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disablePast
                    margin="normal"
                    id="date-picker-dialog"
                    label="Booking Date"
                    format="MM/dd/yyyy"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              {buttonValue !== null ? (
                <div className="col-md-7 text-center mt-sm-0 mt-2">
                  <div className="row card mx-md-2 p-sm-4" id="Specificheight">
                    <h3 className="heading">Choose Slot</h3>
                    <hr />
                    <div className="another-div">
                      {buttonValue.map((value, index) => {
                        return (
                          <div className="d-inline" key={index}>
                            {value.SlotStatus === "Available" ? (
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Third group"
                              >
                                <input
                                  type="button"
                                  value={`${value.FromTime} - ${value.ToTime}`}
                                  id={index}
                                  onClick={callMePLZ}
                                  className="btn btn-outline-success m-sm-2 m-1"
                                />
                              </div>
                            ) : (
                              <div
                                className="btn-group"
                                role="group"
                                aria-label="Third group"
                              >
                                <input
                                  type="button"
                                  id={index}
                                  value={`${value.FromTime} - ${value.ToTime}`}
                                  className="btn btn-danger m-1"
                                  disabled
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="row">
              <div
                className="col-12 text-center my-2 my-sm-4"
                id="step-second-button-row"
              >
                {appointmentDate !== undefined && appTime !== undefined ? (
                  <div className="d-flex justify-content-center">
                    <div className=" col-md-8 text-white show-book-time">
                      <h5 className="mt-2">
                        {appointmentDate === "" && appTime === ""
                          ? setAppointmentDate(
                            "Select date and time for Appointment"
                          )
                          : `Your Appointment Date is ${appointmentDate}, and time is ${appTime}`}
                      </h5>
                    </div>
                  </div>
                ) : (
                  appTime
                )}
                <div className="mt-2 mt-sm-4">
                  <button className="btn btn-success btn-lg">
                    Proceed to Book
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Warning !!!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-danger">{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default StepSecond;
