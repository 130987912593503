import React from "react";
import "./StepSeven.css";
import { Container, Col, Row } from "react-bootstrap";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import logo from "./assets/img/logo/cutis-logo.png"
import { useHistory } from "react-router-dom";
const StepSeven = () => {
  const history = useHistory();
  const docList = sessionStorage.getItem("docinfo");
  const getDocotr = JSON.parse(docList);
  const appointmentDate = sessionStorage.getItem("AppointmentDate");
  const appontmentTime = sessionStorage.getItem("AppointmentTime");
  const patientInfo = JSON.parse(sessionStorage.getItem("patientInfo"));
  const generatePdf = () => {
    window.print()
    // sessionStorage.clear()
  }
  const logMeOut = () => {
    sessionStorage.clear();
    history.push(`/`);
  }
  return (
    <div id="main-div-fluid">
      {/* ----------------upper strip---------------- */}
      <div className="displayNotmeOnPrint">
        <Container fluid id="upper-strip">
          <Container className="pt-2 pb-md-0 pb-3">
            <Row>
              <Col>
                <div className="text-center">
                  <CheckCircleIcon id="step-third-icon" />
                  <i className="fa fa-power-off float-right logout-btn" aria-hidden="true" onClick={logMeOut}></i>
                  <h5 className="text-white my-2">
                    Thank You From Cutis, We Await Your Visit
                  </h5>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>

        {/* ----------down box----------------------- */}
        <Container className="app-detail-box shadow my-2">
          <Row>
            <Col className="d-flex justify-content-center">
              <div className="col-10 col-md-8 my-5 app-detail-heading">
                <h4 className="text-center"> Appointment Detail</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center px-0 px-sm-0">
              <div className="col-md-8 px-1 px-sm-0">
                <ul>
                  <li>
                    <span>Patient&nbsp;Name</span>
                    <span>{patientInfo.PatFirstName} {patientInfo.PatMiddleName} {patientInfo.PatLastName}</span>
                  </li>
                  <li>
                    <span>Specialist</span>
                    <span>{getDocotr && getDocotr[0].Specialisation}</span>
                  </li>
                  <li>
                    <span>Doctor&nbsp;Name</span>
                    <span>{getDocotr && getDocotr[0].Name}</span>
                  </li>
                  <li>
                    <span>Hospital&nbsp;Detail</span>
                    <span>
                      CUTIS&nbsp;Academy&nbsp;of&nbsp;Cutaneous&nbsp;Science
                    </span>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="app-detail-date-time d-flex justify-content-center ">
                <h4>
                  <i className="fas fa-calendar-alt"></i> &nbsp;
                  {appointmentDate}&nbsp;&nbsp;&nbsp;&nbsp;
                  <i className="far fa-clock"></i>&nbsp;
                  {appontmentTime}
                </h4>
              </div>
            </Col>
          </Row>
          <div className="text-center pb-2">
            <button
              className="btn  btn-lg rounded-pill px-3 px-md-4 my-sm-3"
              id="down-pdf"
              onClick={generatePdf}
            >
              Download&nbsp;PDF
            </button>
          </div>
        </Container>

      </div>
      <div className="displaymeonprint">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">
                <p>
                  Dr.&nbsp;B.S.&nbsp;Chandrashekar <br />
                  <small
                  >MD. D.N.B <br />Medical Director<br />Chief Dermatologist</small
                  >
                  <br />
                  Dr. Manjula C.N. <br /><small
                  >MD. OBG <br />CEO <br />Consultant Gynaecologist</small
                  >
                </p>
              </th>
              <th scope="col" className="text-center have-logo-cutis">
                <img
                  src={logo}
                  alt="Not available"
                />
                <address>
                  <p>
                    5/1, 4th Main, MRCR Layout, Vijayanagar (Near Veeresh Theater)
                    <br />
                    Magadi Main Road, Bengaluru 560040<br />
                    Contact: 080 2340 1200 Mob: 082961 10020
                    Email:askcutis@gmail.com Web:www.cutis.org.in
                  </p>
                </address>
              </th>
              <th scope="col">
                <div className="text-right">
                  <p><strong>Monday - Thursday</strong>: 9&nbsp;AM&nbsp;-&nbsp;7&nbsp;PM</p>
                  <p><strong>Friday</strong>: 10 AM - 7 PM</p>
                  <p><strong>Saturday</strong>: 9 AM - 4 PM</p>
                </div>
              </th>
            </tr>
          </thead>
        </table>
        <div className="body-design container my-5 py-5">
          <table width="100%" border="0" id="table-heading-data">
            <tr>
              <th className="some-color">Patient Name</th>
              <td>{patientInfo.PatFirstName} {patientInfo.PatMiddleName} {patientInfo.PatLastName}</td>
            </tr>
            <tr>
              <th className="some-color">Gender</th>
              <td>{patientInfo.Gender}</td>
            </tr>
            <tr>
              <th className="some-color">Contact Number</th>
              <td>{patientInfo.MobileNum}</td>
            </tr>
            <tr>
              <th className="some-color">Patient Age</th>
              <td>{patientInfo.Age}</td>
            </tr>
            <tr>
              <th className="some-color">Doctor Name</th>
              <td>{getDocotr && getDocotr[0].Name}</td>
            </tr>
            <tr>
              <th className="some-color">Appointment Time</th>
              <td>{appontmentTime}</td>
            </tr>
            <tr>
              <th className="some-color">Appointment Date</th>
              <td>{appointmentDate}</td>
            </tr>
          </table>
        </div>
        <div className="bottom-design mt-5 pt-5">
          <h6 className="text-center">
            Order medicines online at
            <a href="www.cutiskart.com" className="text-danger">www.cutiskart.com</a>
          </h6>
          <table className="table table-sm">
            <thead>
              <tr>
                <td style={{ width: "60%" }}>
                  <table style={{ width: "100%" }}>
                    <th colSpan="3" className="text-center bg-info text-white" id="table-head">
                      <span>Facilities</span>
                    </th>
                    <tr>
                      <td>
                        <ul>
                          <li>Hair Transplaintaion <br />- FUT & FUE</li>
                          <li>
                            PRP - For hair Growth <br />
                            & facial Rejuvenation
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Earlobe Repair</li>
                          <li>Mole Surgery</li>
                          <li>Fat Transfer</li>
                          <li>Acne Scar Surgery</li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Sear Revisions</li>
                          <li>Vitiligo Surgery</li>
                          <li>Nail Surgery</li>
                          <li>Sclero Therapy</li>
                        </ul>
                      </td>
                    </tr>
                  </table>
                </td>
                <td>
                  <h5 className="text-danger">Please Note: Points to remember</h5>
                  <ul>
                    <li>To take photographs of affected area in Room No. 8/12</li>
                    <li>
                      Drugs can cause reactions for any side effects report
                      immediately by Mail/ Phone/ In person to us.
                    </li>
                  </ul>
                </td>
              </tr>
            </thead>
          </table>
          <h2 className="text-danger text-center mb-5">
            kindly Note: "Cutis has no Branches" Sunday Holiday
          </h2>
        </div>
      </div>
    </div>
  );
};

export default StepSeven;
