import React, { useState, useEffect } from "react";
import "./StepSix.css";
import {
  Container,
  Col,
  Row,
  Modal,
  Button
} from "react-bootstrap";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import { useHistory } from "react-router-dom";
import axios from "axios";

const StepSix = (props) => {
  const uri = `${process.env.REACT_APP_URL}`
  const [show, setShow] = useState(false);
  const getdoctorID = props.match.params.doctorid;
  const userMobileNumber = sessionStorage.getItem("mobileNumber");
  const appointmentDate = sessionStorage.getItem("AppointmentDate");
  const appontmentTime = sessionStorage.getItem("AppointmentTime");
  const userOTP = sessionStorage.getItem("userOTP");
  const [errorMessage, seterrorMessage] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const history = useHistory();
  const [patient, setPatient] = useState(null);


  useEffect(() => {
    axios
      .get(
        `${uri}/Patient/GetPatientDetails?MobileNum=${userMobileNumber}`
      )
      .then((response) => {
        setPatient(JSON.parse(response.data.Data));
      })
      .catch((err) => {
        console.warn(err);
      });
  }, [userMobileNumber, uri]);

  const docList = sessionStorage.getItem("docinfo");
  const getDocotr = JSON.parse(docList);

  const handleAppointment = (event) => {
    const patientData = document.getElementById("patient").value
    if ("Select patient" === patientData) {
      seterrorMessage(`Patient not selected`);
      openModal();
    }
    patient.map((value, index) => {
      if (value.UHID === patientData) {
        sessionStorage.setItem("patientInfo", JSON.stringify(value))
        const dateOfBirth = value.DOB.split(" ")[0].split("/")
        const month = dateOfBirth[0] < 9 ? `0${dateOfBirth[0]}` : `${dateOfBirth[0]}`
        const day = dateOfBirth[1] < 9 ? `0${dateOfBirth[1]}` : `${dateOfBirth[1]}`
        const finalDate = `${dateOfBirth[2]}-${month}-${day}`
        const appTime = appontmentTime.split("-")
        const appDate = appointmentDate.split("/")
        const finalAppDate = `${appDate[2]}-${appDate[1]}-${appDate[0]}`
        const fromTime = `${finalAppDate} ${appTime[0]}`
        const toTime = `${finalAppDate}${appTime[1]}`
        const genderID = value.Gender === "Female" ? "F" : "M"
        const age = value.Age.split(" ")
        const finalAge = `${age[0]}Yrs`
        const uhid = value.UHID
        const finalUHID = uhid.slice(2, uhid.length)
        axios.post(`${uri}/Doctor/SaveAppointment?hospitalid=1&Doc_id=${getdoctorID}&title_id=${value.PatSalutationCode}&first_name=${value.PatFirstName}&middle_name=N&last_name=${value.PatLastName}&gender=${genderID}&dob=${finalDate}&age=${finalAge}&appointment_date=${finalAppDate}&from_time=${fromTime.slice(0, 16)}&to_time=${toTime.slice(0, 16)}&description=Web Application&phoneno=${value.MobileNum}&OTP=${userOTP}&Uhid=${finalUHID}`)
          .then(({ data }) => {
            const UserMsg = data.Message
            if ("Doctor Appointment Details Successfully" === UserMsg.slice(0, 39))
              history.push(`/step-seven`)
            else {
              seterrorMessage(UserMsg);
              openModal();
              event.preventDefault()
            }
          }).catch((err) => {
            console.warn(err);
          });
      }
      return index
    })
  };
  const openModal = () => {
    handleShow(true);
  };
  const addnewMember = () => {
    history.push(`/register/doctorid/${getdoctorID}`)
  }

  return (
    <div id="main-div-fluid">
      {/* ----------------upper strip---------------- */}
      <Container fluid id="upper-strip">
        <Container className="pt-4 pb-md-0 pb-3">
          <Row>
            <Col md={6} id="doctor-detail" className="d-flex">
              <AddCircleIcon id="step-third-icon" />
              <div className="text-white">
                <h4>{getDocotr && getDocotr[0].Name}</h4>
                <p>{getDocotr && getDocotr[0].Specialisation}&nbsp;&<br />
                  {getDocotr && `${getDocotr[0].Designation}`}</p>
              </div>
            </Col>
            <Col md={6} id="back-button">
              <button
                className="btn btn-light rounded-pill px-3 px-md-4 float-md-right mt-1"
                onClick={history.goBack}
              >
                Back
              </button>
            </Col>
          </Row>
        </Container>
      </Container>
      {/* ------------------------login/signup------------------------ */}

      <Container>
        <Row className="login-row">
          <Col md={6} className="login-box d-flex justify-content-center" >
            <div className="login-sign-box text-center text-white py-sm-5" id="made-color">
              <CheckCircleIcon id="tick-icon" />
              <h3 className="py-1">Welcome&nbsp;Back!</h3>
              <p className="mb-sm-5 mb-2">
                {patient !== null &&
                  `${patient[patient.length - 1].PatFirstName} ${patient[patient.length - 1].PatMiddleName
                  } ${patient[patient.length - 1].PatLastName}`}
              </p>
              <p className="mt-sm-5 mt-2">
                In case if multiple patients are registered under this number,
                please select the patient requiring consultation
              </p>
            </div>
          </Col>
          <Col
            md={6}
            className=" text-center m-0 tip-box  d-flex justify-content-center"
            id="add-patient"
          >
            <div className="col-md-11">
              <div className="box-color1 mb-2 px-2">
                <i className="fas fa-male step-first-icon"></i>
                <select
                  className="form-select form-select-lg py-2 mb-4 rounded-pill px-sm-5 py-1 text-center"
                  name="patient"
                  id="patient"
                  required
                  defaultValue={`Select patient`}
                >
                  <option disabled defaultValue={`Select patient`}>
                    Select patient
                  </option>
                  {patient !== null &&
                    patient.map((value, index) => {
                      return (
                        <option value={value.UHID} key={index}>
                          {value.PatFirstName} {value.PatMiddleName}{" "}
                          {value.PatLastName}
                        </option>
                      );
                    })}
                </select>
                <br />

                <div className="my-3">
                  <button
                    className="btn btn-lg bg-success rounded-pill p-sm-3 p-2" id="grad-color"
                    onClick={handleAppointment}
                  >
                    Book&nbsp;your&nbsp;appointment
                  </button>
                </div>
              </div>

              <div className="box-color ">
                <button
                  className="btn btn-lg bg-white rounded-pill px-3 px-md-4 mt-sm-5"
                  onClick={addnewMember}
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <AddCircleOutlineIcon id="step-six-icon" />
                  &nbsp;Add&nbsp;Member
                </button>
                <br />
                <div className="my-4">
                  <p className="text-danger">
                    <strong>To add your family members with same mobile number please
                      click above</strong>
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Warning !!!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-danger">{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default StepSix;
