import { Route, Switch } from "react-router-dom";
import StepFirst from "./components/StepFirst";
import StepSecond from "./components/StepSecond";
import StepThird from "./components/StepThird";
import StepFour from "./components/StepFour";
import StepSix from "./components/StepSix";
import StepSeven from "./components/StepSeven";
import Register from "./components/Register";
import Error from "./components/Error";
import FinalStep from "./components/FinalStep";
import PrivateRoute from "./service/PrivateRoute";

const App = () => {
  return (
    <>
      <Switch>
        <Route exact path="/" component={StepFirst} />
        <Route
          exact
          path="/step-second/doctorid/:doctorid"
          component={StepSecond}
        />
        <Route
          exact
          path="/step-third/doctorid/:doctorid"
          component={StepThird}
        />
        <Route exact path="/done-final" component={FinalStep} />

        <Route
          exact
          path="/step-four/doctorid/:doctorid"
          component={StepFour}
        />

        {/* <PrivateRoute path="/onlyAuthorizedAllowedHere/" component={MyComponent} /> */}

        <PrivateRoute
          exact
          path="/step-six/doctorid/:doctorid"
          component={StepSix}
        />
        <PrivateRoute
          exact
          path="/register/doctorid/:doctorid"
          component={Register}
        />
        <PrivateRoute exact path="/step-seven" component={StepSeven} />
        <Route component={Error} />
      </Switch>
      {/* <All /> */}
    </>
  );
};

export default App;
