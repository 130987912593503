import React from "react";
import "./StepThird.css";
import { Container, Col, Row } from "react-bootstrap";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import drimg from "./assets/img/background/logo.png";
import { useHistory } from "react-router-dom";

const StepThird = (props) => {
  const history = useHistory();
  const docList = sessionStorage.getItem("docinfo");
  const getDocotr = JSON.parse(docList);
  return (
    <div id="main-div-fluid">
      {/* ----------------upper strip---------------- */}
      <Container fluid id="upper-strip">
        <Container className="pt-4 pb-md-0 pb-3">
          <Row>
            <Col md={6} id="doctor-detail" className="d-flex">
              {/* <div className="mx-2 my-1  step-third-icon">
                <i className="fas fa-plus"></i>
              </div> */}
              <AddCircleIcon id="step-third-icon" />
              <div className="text-white">
                <h4>{getDocotr && getDocotr[0].Name}</h4>
                <p>{getDocotr && getDocotr[0].Specialisation}&nbsp;&<br />
                  {getDocotr && `${getDocotr[0].Designation}`}</p>
              </div>
            </Col>
            <Col md={6} id="back-button">
              <button
                className="btn btn-light rounded-pill px-3 px-md-4 float-md-right mt-1"
                onClick={history.goBack}
              >
                Back
              </button>
            </Col>
          </Row>
        </Container>
      </Container>

      {/* ----------------------doctor- profile----------------------- */}
      <Container fluid className="dr-profile mt-5">
        <h2 className="text-center mb-5">PROFILE</h2>
        <Row>
          <Col md={5}>
            <div className="half-circle">
              <div className="doctor-pic-circle">
                <img src={drimg} alt="" />
              </div>
            </div>
          </Col>
          <Col md={7} id="doc-detail">
            <h1 className="">{getDocotr && getDocotr[0].Name}</h1>
            <ul>
              <li>
                <span>Qualification</span>
                <span>{getDocotr && getDocotr[0].Qualification}</span>
              </li>
              <li>
                <span>Specialization</span>
                <span>{getDocotr && getDocotr[0].Specialisation}</span>
              </li>
              <li>
                <span>Designation</span>
                <span>{getDocotr && getDocotr[0].Designation}</span>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default StepThird;
