import React from "react";
import { useHistory } from "react-router-dom";
const Error = () => {
  const history = useHistory();
  return (
    <div>
      <div className="good">
        <button
          className="btn btn-danger"
          onClick={() => {
            history.push("/");
          }}
        >
          Login
        </button>
      </div>
      <strong>Errro 404 page not Found</strong>
    </div>
  );
};

export default Error;
