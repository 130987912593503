import React, { useState, useEffect } from "react";
import "./StepFirst.css";
import { Container, Col, Row, Modal, Button } from "react-bootstrap";
import logo from "./assets/img/logo/cutis-logo.png";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useHistory } from "react-router-dom";
const StepFirst = () => {
  const [specialisationList, setSpecialisationList] = useState([]);
  const uri = `${process.env.REACT_APP_URL}`;
  const [DoctorsList, setDoctorsList] = useState([]);
  const [specialistDefaultValue, setspecialistDefaultValue] =
    useState("Select Speciality");
  const [doctorId, setDoctorId] = useState(null);
  const history = useHistory();
  const [searchDoctorList, setsearchDoctorList] = useState([]);
  const [errorMessage, seterrorMessage] = useState(null);
  const fetchDoctorData = async () => {
    return await axios
      .get(`${uri}/Doctor/GetDoctorDetails?hospitalid=1`)
      .then(({ data }) => {
        return data;
      })
      .catch((err) => {
        console.warn(err);
      });
  };
  useEffect(() => {
    setspecialistDefaultValue("Select Speciality");

    const speciliationArray = [
      { ID: 0, Name: "Skin" },
      { ID: 0, Name: "Hair" },
      { ID: 0, Name: "Nail" },
      { ID: 7, Name: "Pediatric Dermatology" },
    ];
    setSpecialisationList(speciliationArray);
    fetchDoctorData().then((resp) => {
      setsearchDoctorList(JSON.parse(resp.Availability));
    });
  }, []);

  function getUniqueListBy(searchDoctorList, key) {
    return [
      ...new Map(searchDoctorList.map((item) => [item[key], item])).values(),
    ];
  }
  const searchListOfDoctor = getUniqueListBy(searchDoctorList, "DoctorId");
  const filteredArray = searchListOfDoctor.filter((x) => x.DoctorId !== "3");

  const OnChangeGetSpecialiationSearchObj = (newValue) => {
    if (newValue !== null) {
      setDoctorId(newValue.DoctorId);
      history.push(`/step-second/doctorid/${newValue.DoctorId}`);
    } else {
      setDoctorId(
        fetchDoctorData().then((resp) => {
          setsearchDoctorList(JSON.parse(resp.Availability));
        })
      );
    }
  };
  const handleDoctorId = (e) => {
    setDoctorId(e.target.value);
  };

  const getDoctorDetails = (e) => {
    let selectedDocID = e.target.value;
    if (0 === parseInt(selectedDocID)) {
      axios
        .get(
          `${uri}/Doctor/GetDoctorProfile?DoctorId=${selectedDocID}&hospitalid=1`
        )
        .then(({ data }) => {
          if (data.Data !== "No Data Found") {
            let doctorsArray = [];
            let parseData = JSON.parse(data.Data);
            parseData.map((item) => {
              if (
                item.Name !== "Dr. CHANDRASHEKAR  B S C 1" &&
                item.Designation !== "Fellow" &&
                item.Designation !== "Registrar" &&
                item.Designation !== "psychiatrist" &&
                item.Designation !== "Dietitian" &&
                item.Code !== "RASHMI" &&
                item.Designation !== "Plastic Surgeon"
              )
                return doctorsArray.push(item);
              return item;
            });
            setDoctorsList(doctorsArray);
          } else {
            seterrorMessage("No doctors are currently avialiable now");
            openModal();
            let doctorsArray = [];
            setDoctorsList(doctorsArray);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      axios
        .get(
          `${uri}/Doctor/GetSpecialisationforDoctor?SpecialisationId=${selectedDocID}&hospitalid=1`
        )
        .then(({ data }) => {
          if (data.Data !== "No Data Found") {
            // console.log(data.Data)
            let doctorsArray = [];
            let parseData = JSON.parse(data.Data);
            parseData.map((item) => {
              return doctorsArray.push(item);
            });
            setDoctorsList(doctorsArray);
          } else {
            seterrorMessage("No doctors are currently avialiable now");
            openModal();
            let doctorsArray = [];
            setDoctorsList(doctorsArray);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const handleSubmit = (e) => {
    if (
      document.getElementById("specialist").value === "Select Speciality" ||
      document.getElementById("doctor").value === "Select doctor"
    ) {
      seterrorMessage("You have to Select Specialization and Doctor");
      openModal();
      e.preventDefault();
    } else {
      history.push(`/step-second/doctorid/${doctorId}`);
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const openModal = () => {
    handleShow(true);
  };

  return (
    <div>
      <Container fluid id="main-div-fluid">
        <Container>
          <Row>
            <Col id="logo">
              <img src={logo} alt="not available" />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex justify-content-center">
              <div className="start-book text-center" id="start-book">
                <h2 className="text-white">Book Appointment</h2>
                <br />
                <form onSubmit={handleSubmit}>
                  <Autocomplete
                    id="combo-box-demo"
                    options={filteredArray}
                    getOptionLabel={(option) => option.DoctorName}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Search Doctor"
                      // onClick={resetValue}
                      />
                    )}
                    onChange={(event, newValue) =>
                      OnChangeGetSpecialiationSearchObj(newValue)
                    }
                  />
                  <h2 className="my-sm-3 my-1 text-white">
                    <strong>or</strong>
                  </h2>
                  <i className="fas fa-stethoscope mx-2 step-first-icon"></i>
                  <select
                    className="form-select form-select-lg py-2 mb-4 rounded-pill px-sm-5 py-1 text-center"
                    name="specialist"
                    id="specialist"
                    required
                    defaultValue={specialistDefaultValue}
                    onChange={getDoctorDetails}
                  >
                    <option defaultValue={specialistDefaultValue}>
                      {specialistDefaultValue}
                    </option>
                    {specialisationList.map((value, index) => {
                      return (
                        <option key={index} value={value.ID}>
                          {value.Name}
                        </option>
                      );
                    })}
                  </select>
                  <br />
                  <i className="fas fa-user-md mx-2 step-first-icon"></i>
                  <select
                    className="form-select form-select-lg py-2 mb-4 rounded-pill px-sm-5 py-1 text-center"
                    name="doctor"
                    id="doctor"
                    required
                    defaultValue={"Select doctor"}
                    onChange={handleDoctorId}
                  >
                    <option disabled defaultValue={"Select doctor"}>
                      Select doctor
                    </option>
                    {DoctorsList.map((value, index) => {
                      return (
                        <option key={index} value={value.ID}>
                          {value.Name}
                        </option>
                      );
                    })}
                  </select>
                  <div className="text-center mt-sm-3 mt-1">
                    <button
                      type="submit"
                      className="btn btn-primary rounded-pill px-4 px-md-5"
                      id="rounded-pill"
                    >
                      Next
                    </button>
                  </div>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-danger">Warning !!!</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-danger">{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-danger" onClick={handleClose}>
            Okay
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default StepFirst;
