import React from 'react'
import logo from "./assets/img/logo/cutis-logo.png";
const FinalStep = () => {
   return (
      <div>
         <div className="logo">
            <div className="img-text-center">
               <img src={logo} alt="not available" />
            </div>
         </div>
         <hr />
         <div className="having-data">
            <table style={{ width: "100%" }}>
               <tr>
                  <th>Firstname</th>
                  <th>Lastname</th>
                  <th>Age</th>
               </tr>
               <tr>
                  <td>Jill</td>
                  <td>Smith</td>
                  <td>50</td>
               </tr>
               <tr>
                  <td>Eve</td>
                  <td>Jackson</td>
                  <td>94</td>
               </tr>
            </table>
         </div>
      </div>
   )
}

export default FinalStep
